export class CookieBarController {

  constructor() {
    this.init();
  }

  init() {
    window.addEventListener('hashchange', () => {
      this.hashChangeListener();
    });

    const currentCookie = getCookie('acceptCookies');
    if (currentCookie !== 'necessary' && currentCookie !== 'all') {
      this.showOverlay();
    } else if (currentCookie === 'all') {
      this.activateTracking();
    }

    const acceptAllButton = document.querySelector('.js-acceptAll');
    if (acceptAllButton) {
      acceptAllButton.addEventListener('click', () => {
        this.acceptCookies('all');
        this.closeOverlay();
      });
    }

    const acceptSelectionButton = document.querySelector('.js-acceptSelection');
    if (acceptSelectionButton) {
      acceptSelectionButton.addEventListener('click', () => {
        const statisticCookie = document.querySelector('.js-statisticCookie') as HTMLInputElement;
        if (statisticCookie && statisticCookie.checked) {
          this.acceptCookies('all');
        } else {
          this.acceptCookies('necessary');
        }
        this.closeOverlay();
      });
    }
  }

  closeOverlay() {
    const cookieOverlay = document.querySelector('.js-cookieOverlay');
    if (cookieOverlay) {
      cookieOverlay.classList.remove('cookie--show');
    }
    location.reload();
  }

  showOverlay() {
    if (window.location.hash === '#noCookieMessage') {
      const url = location.href.replace(/#.*/, '');
      history.pushState({}, '', url);
    } else {
      const cookieOverlay = document.querySelector('.js-cookieOverlay');
      const statisticButton = document.querySelector('.js-statisticCookie') as HTMLInputElement;
      if (cookieOverlay) {
        if (statisticButton && hasComfortCookies()) {
          statisticButton.checked = true;
        }
        cookieOverlay.classList.add('cookie--show');
      }
    }
  }

  acceptCookies(status) {
    if (status === 'all') {
      this.activateTracking();
    }
    document.cookie = 'acceptCookies=' + status;
  }

  hashChangeListener() {
    const urlParts = document.URL.split('#');
    const anchor = (urlParts.length > 1) ? urlParts[1] : null;
    if (anchor === 'cookies') {
      this.showOverlay();
      const url = location.href.replace(/#.*/, '');
      history.pushState({}, '', url);
    }
  }

  activateTracking() {
    const cookieOverlay = document.querySelector('.js-cookieOverlay') as HTMLElement;
    if (typeof cookieOverlay && cookieOverlay.dataset.context === 'Production') {
      let script = document.createElement('script');
      script.innerHTML = '(function(w,d,s,l,i){w[l]=w[l]||[];w[l].push({\'gtm.start\':\n' +
        '    new Date().getTime(),event:\'gtm.js\'});var f=d.getElementsByTagName(s)[0],\n' +
        '  j=d.createElement(s),dl=l!=\'dataLayer\'?\'&l=\'+l:\'\';j.async=true;j.src=\n' +
        '  \'https://www.googletagmanager.com/gtm.js?id=\'+i+dl;f.parentNode.insertBefore(j,f);\n' +
        '})(window,document,\'script\',\'dataLayer\',\'GTM-MTNGWTP\');';
      document.head.appendChild(script);
    }
  }
}

export function getCookie(cname): string {
  const name = cname + '=';
  const decodedCookie = decodeURIComponent(document.cookie);
  const ca = decodedCookie.split(';');
  for (let i = 0; i < ca.length; i++) {
    let c = ca[i];
    while (c.charAt(0) === ' ') {
      c = c.substring(1);
    }
    if (c.indexOf(name) === 0) {
      return c.substring(name.length, c.length);
    }
  }
  return '';
}

export function hasComfortCookies(): boolean {
  return getCookie('acceptCookies') === 'all';
}
