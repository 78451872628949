import 'es6-promise/auto';
import 'whatwg-fetch';
import {domReady} from './Functions/DomReady';
import * as Raven from 'raven-js';
import {NavigationController} from './Controller/NavigationController';
import {detectBrowser} from './Functions/BrowserDetection';
import {CookieBarController} from './Controller/CookiebarController';
import {FormValidator} from './Form/FormValidator';
import {InfoboxController} from './Form/InfoboxController';
import {initFocusPoint} from './Functions/FocusPoint';
import {ListSortController} from './Controller/ListSortController';
import {OfferSumController} from './Controller/OfferSumController';
import {SlotAndOfferNumberController} from './Controller/SlotAndOfferNumberController';
import {CloseInquiryAlertBoxController} from './Controller/CloseInquiryAlertBoxController';
import {SendMailCancelInquiryController} from './Controller/SendMailCancelInquiryController';
import {ArticleListNavController} from './Controller/ArticleListNavController';
import {polyfillForIe} from './Functions/Utilities';
import {LoginStateController} from './Login/LoginState';

if (module.hot) {
  module.hot.accept();
}

Raven.config('https://16a2f08d0d474cb99a0c03a662767ece@sentry.andersundsehr.com/15').install();

Raven.context(() => {
  domReady(() => {
    polyfillForIe();
    new NavigationController;
    new LoginStateController();
    new CookieBarController();
    new FormValidator();
    new InfoboxController();
    new ListSortController();
    new OfferSumController();
    new SlotAndOfferNumberController();
    new CloseInquiryAlertBoxController();
    new SendMailCancelInquiryController();
    new ArticleListNavController();
    detectBrowser();
    initFocusPoint();

    if (document.getElementsByClassName('js-formUpload').length) {
      import(/* webpackChunkName: "FileUploadValidator" */ './FileUpload/FileUploadValidator').then(module => {
        new module.FileUploadValidator('js-formUpload');
      });
    }

    if (document.getElementsByClassName('js-accordions').length) {
      import(/* webpackChunkName: "Accordion" */ './Controller/AccordionController').then(module => {
        new module.AccordionController();
      });
    }

    if (document.getElementsByClassName('js-media-gallery').length) {
      import(/* webpackChunkName: "MediaGallery" */ './MediaGallery/MediaGalleryFactory').then(module => {
        new module.MediaGalleryFactory('js-media-gallery');
      });
    }

    const counters = document.getElementsByClassName('js-count-up');
    if (counters.length) {
      import(/* webpackChunkName: "CountUpElement" */ './Content/CountUpController').then(module => {
        new module.CountUpController(counters);
      });
    }

    if (document.getElementsByClassName('js-articleSliderMain').length) {
      import(/* webpackChunkName: "ArticleSlider" */ './Slider/ArticleSlider').then(module => {
        new module.ArticleSlider();
      });
    }

    if (document.getElementsByClassName('js-normalSlider').length) {
      import(/* webpackChunkName: "NormalSlider" */ './Slider/NormalSlider').then(module => {
        new module.NormalSlider();
      });
    }

    if (document.getElementsByClassName('js-testimonialSlider').length) {
      import(/* webpackChunkName: "TestimonialSlider" */ './Slider/TestimonialSlider').then(module => {
        new module.TestimonialSlider();
      });
    }

    if (document.getElementsByClassName('js-logoSlider').length) {
      import(/* webpackChunkName: "LogoSlider" */ './Slider/LogoSlider').then(module => {
        new module.LogoSlider();
      });
    }

    if (document.getElementsByClassName('js-testimonialSlider').length) {
      import(/* webpackChunkName: "TestimonialSlider" */ './Slider/TestimonialSlider').then(module => {
        new module.TestimonialSlider();
      });
    }

    if (document.getElementsByClassName('js-article-jump-nav').length) {
      import(/* webpackChunkName: "ArticlePage" */ './Controller/ArticleJumpNavController').then(module => {
        new module.ArticleJumpNavController();
      });
    }

    if (document.getElementsByClassName('js-configurator').length) {
      import(/* webpackChunkName: "Configurator" */ './Configurator/Configurator').then(module => {
        new module.Configurator();
      });
    }

    if (document.getElementsByClassName('js-showAllCarpentries__map').length) {
      import(/* webpackChunkName: "Configurator" */ './Controller/CarpentryMapController').then(module => {
        new module.CarpentryMapController();
      });
    }
  });
});
