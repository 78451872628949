export class CloseInquiryAlertBoxController {
  private quicklinks;

  constructor() {
    this.quicklinks = document.querySelectorAll('.js-carpentryList__quicklink');
    if (this.quicklinks.length) {
      this.initializeCloseInquiryAlertBoxController();
    }
  }
  initializeCloseInquiryAlertBoxController() {
    for (let quicklink of this.quicklinks) {
      quicklink.addEventListener('click', (e) => {
        if (!confirm('Wollen Sie die E-Mail absenden und diese Anfrage schließen?')) {
          e.preventDefault();
        }
      });
    }
  }
}
