export class SendMailCancelInquiryController {
  private cancelReasonMainTexts;

  constructor() {
    let reason1: HTMLInputElement | null = document.querySelector('.js-cancel-reason-main-text-1');
    let reason2: HTMLInputElement | null = document.querySelector('.js-cancel-reason-main-text-2');
    let reason3: HTMLInputElement | null = document.querySelector('.js-cancel-reason-main-text-3');
    if (reason1 && reason2 && reason3) {
      this.cancelReasonMainTexts = [
        'NOT_USED', // not used
        reason1.value,
        reason2.value,
        reason3.value,
      ];
    }

    const accordions = document.querySelectorAll('.accordions.js-accordions.js-carpentryList .accordion.carpentryList__inquiry');
    [].forEach.call(accordions, (accordion) => {
      if (!accordion.classList.contains('carpentryList__inquiry--closed')) {
        this.initializeSendMailCancelInquiryController(accordion);
      }
    });
  }

  initializeSendMailCancelInquiryController(accordion) {
    let cancelInquiryTextDefault = accordion.querySelector('.js-cancel-inquiry-text-default');
    let cancelInquiryText = accordion.querySelector('.js-cancel-inquiry-text');
    let select = accordion.querySelector('.js-form-cancel select');

    // init: set cancel reason 1
    if (cancelInquiryTextDefault && cancelInquiryText && select) {
      cancelInquiryText.value = cancelInquiryTextDefault.value.trim();
      cancelInquiryText.value = cancelInquiryText.value.replace(
        '###CANCEL_REASON_MAIN_TEXT###',
        this.cancelReasonMainTexts[1].trim()
      );

      // set cancel reason according to selected reason
      select.addEventListener('change', () => {
        cancelInquiryText.value = cancelInquiryTextDefault.value.trim();
        cancelInquiryText.value = cancelInquiryText.value.replace(
          '###CANCEL_REASON_MAIN_TEXT###',
          this.cancelReasonMainTexts[select.value].trim()
        );
      });
    }
  }
}
