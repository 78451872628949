import {clearAllBodyScrollLocks, disableBodyScroll} from 'body-scroll-lock';

const generalHideClass = 'hide';
const infoboxContents = document.querySelectorAll('.js-infoboxContent');
let domElement = document.createElement('div');
domElement.className = 'infobox__contentOverlay';

export class InfoboxController {
  private readonly openButtons;
  private readonly closeButtons;

  constructor() {
    this.openButtons = document.querySelectorAll('.js-openInfobox');
    this.closeButtons = document.querySelectorAll('.js-closeInfobox');
    for (let openButton of this.openButtons) {
      openButton.addEventListener('click', (e) => {
        e.stopPropagation();
        e.preventDefault();
        openInfobox(openButton);
      });
    }
    for (let closeButton of this.closeButtons) {
      closeButton.addEventListener('click', () => {
        closeInfobox(closeButton);
      });
    }

    document.body.addEventListener('keyup', (e) => {
      if (e.key === 'Escape') {
        for (let closeButton of this.closeButtons) {
          closeInfobox(closeButton);
        }
      }
    });

    domElement.addEventListener('click', () => {
      closeAllInfoboxes();
    });
  }
}

export function openInfobox(openButton) {
  document.body.classList.add('body--infoboxOpen');
  document.body.appendChild(domElement);

  [].forEach.call(infoboxContents, (infoboxContent) => {
    infoboxContent.classList.add(generalHideClass);
  });
  openButton.nextElementSibling.classList.remove(generalHideClass);
  disableBodyScroll(openButton.nextElementSibling, {reserveScrollBarGap: true});
}

export function closeInfobox(closeButton) {
  document.body.classList.remove('body--infoboxOpen');
  if (closeButton.classList && !closeButton.classList.contains('button--inactive')) {
    closeButton.parentNode.classList.add(generalHideClass);
    clearAllBodyScrollLocks();
  }
}

function closeAllInfoboxes() {
  document.body.classList.remove('body--infoboxOpen');
  [].forEach.call(document.querySelectorAll('.js-infoboxContent'), (infobox) => {
    infobox.classList.add(generalHideClass);
  });
  clearAllBodyScrollLocks();
}
