export class ArticleListNavController {
  private select;
  private links;
  private readonly scrollToElement = require('scroll-to-element');

  constructor() {
    const selects = document.querySelectorAll('.js-highlightedArticle__categoriesSelect');
    this.links = document.querySelectorAll('.js-articleListNavLink');
    if (selects.length) {
      this.select = selects[0];
      this.ArticleListMobileNavController();
    }
  }
  ArticleListMobileNavController() {
    this.select.addEventListener('change', () => {
      if (document.getElementById(this.select.value) !== null) {
        this.scrollToElement(document.getElementById(this.select.value), {
          offset: -60,
          duration: 500,
        });
        location.hash = this.select.value;
      }
    });
    for (let link of this.links) {
      link.addEventListener('click', () => {
        if (document.getElementById(link.innerHTML.toLowerCase()) !== null) {
          this.scrollToElement(document.getElementById(link.innerHTML.toLowerCase()), {
            offset: -60,
          });
        }
      });
    }
  }
}
