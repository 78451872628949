import {detect} from 'detect-browser';

export function detectBrowser(): void {
  const browser = detect();
  const html = document.getElementsByTagName('html')[0];

  if (browser && browser.os && html) {
    html.classList.add(makeNiceString(browser.os.toString()));
    html.classList.add(makeNiceString(browser.name));
    html.classList.add(makeNiceString(browser.name) + '--' + makeNiceString(browser.version));
  }

  if (isTouchDevice()) {
    html.classList.add('touch');
  }
}

function makeNiceString(input: string): string {
  const output = input.toLowerCase().replace(' ', '-');
  if (output.indexOf('.') < 0) {
    return output;
  }
  return output.substr(0, output.indexOf('.'));
}

export function isTouchDevice(): boolean {
  return (('ontouchstart' in window)
    || (navigator.maxTouchPoints > 0)
    || (navigator.msMaxTouchPoints > 0));
}
