export class ListSortController {
  private statusButton;
  private dateButton;
  private typeButton;
  private zipButton;
  private inquiries;
  private list;
  private currentSort;
  private sortClass = '.sort__icon';
  private rotateClass = 'rotate-180';
  private hideClass = 'hide';

  constructor() {
    if (document.querySelectorAll('.js-carpentryList').length) {
      this.initializeListSortController();
    }
  }

  initializeListSortController() {
    this.list = document.getElementsByClassName('js-carpentryList')[0];
    this.statusButton = document.getElementsByClassName('js-sort-status')[0];
    this.dateButton = document.getElementsByClassName('js-sort-date')[0];
    this.typeButton = document.getElementsByClassName('js-sort-type')[0];
    this.zipButton = document.getElementsByClassName('js-sort-zip')[0];

    if (this.statusButton) {
      this.statusButton.addEventListener('click', () => {
        this.sort('.inquiry__status');
        this.statusButton.querySelector(this.sortClass).classList.remove(this.hideClass, this.rotateClass);
        this.dateButton.querySelector(this.sortClass).classList.add(this.hideClass);
        this.typeButton.querySelector(this.sortClass).classList.add(this.hideClass);
        this.zipButton.querySelector(this.sortClass).classList.add(this.hideClass);
        if (this.currentSort === '.inquiry__status-reverse') {
          this.statusButton.querySelector(this.sortClass).classList.add(this.rotateClass);
        }
      });
    }
    this.dateButton.addEventListener('click', () => {
      this.sort('.inquiry__date');
      if (this.statusButton)
      this.statusButton.querySelector(this.sortClass).classList.add(this.hideClass);
      this.dateButton.querySelector(this.sortClass).classList.remove(this.hideClass, this.rotateClass);
      this.typeButton.querySelector(this.sortClass).classList.add(this.hideClass);
      this.zipButton.querySelector(this.sortClass).classList.add(this.hideClass);
      if (this.currentSort === '.inquiry__date-reverse') {
        this.dateButton.querySelector(this.sortClass).classList.add(this.rotateClass);
      }
    });
    this.typeButton.addEventListener('click', () => {
      this.sort('.inquiry__type');
      if (this.statusButton)
      this.statusButton.querySelector(this.sortClass).classList.add(this.hideClass);
      this.dateButton.querySelector(this.sortClass).classList.add(this.hideClass);
      this.typeButton.querySelector(this.sortClass).classList.remove(this.hideClass, this.rotateClass);
      this.zipButton.querySelector(this.sortClass).classList.add(this.hideClass);
      if (this.currentSort === '.inquiry__type-reverse') {
        this.typeButton.querySelector(this.sortClass).classList.add(this.rotateClass);
      }
    });
    this.zipButton.addEventListener('click', () => {
      this.sort('.inquiry__zip');
      if (this.statusButton)
      this.statusButton.querySelector(this.sortClass).classList.add(this.hideClass);
      this.dateButton.querySelector(this.sortClass).classList.add(this.hideClass);
      this.typeButton.querySelector(this.sortClass).classList.add(this.hideClass);
      this.zipButton.querySelector(this.sortClass).classList.remove(this.hideClass, this.rotateClass);
      if (this.currentSort === '.inquiry__zip-reverse') {
        this.zipButton.querySelector(this.sortClass).classList.add(this.rotateClass);
      }
    });
    this.dateButton.click();
  }

  sort(filter: string) {
    let switching = true;

    /* Make a loop that will continue until
    no switching has been done: */
    while (switching) {
      this.inquiries = document.getElementsByClassName('carpentryList__inquiry');
      this.inquiries = Array.prototype.slice.call(this.inquiries);
      // Start by saying: no switching is done:
      switching = false;
      // Loop through all list items:
      for (let i = 1; i < this.inquiries.length; i++) {
        /* Check if the next item should
        switch place with the current item: */
        if (this.currentSort === filter) {
          if (this.inquiries[i].previousElementSibling.querySelector(filter).dataset.value >
            this.inquiries[i].querySelector(filter).dataset.value) {
            /* If next item is alphabetically higher than current item,
            mark as a switch and break the loop: */
            if (this.inquiries[i].previousElementSibling) {
              this.inquiries[i].parentNode.insertBefore(this.inquiries[i], this.inquiries[i].previousElementSibling);
            }
            switching = true;
          }
        } else {
          if (this.inquiries[i].previousElementSibling.querySelector(filter).dataset.value <
            this.inquiries[i].querySelector(filter).dataset.value) {
            /* If next item is alphabetically lower than current item,
            mark as a switch and break the loop: */
            if (this.inquiries[i].previousElementSibling) {
              this.inquiries[i].parentNode.insertBefore(this.inquiries[i], this.inquiries[i].previousElementSibling);
            }
            switching = true;
          }
        }
      }
    }
    if (this.currentSort === filter) {
      this.currentSort += '-reverse';
    } else {
      this.currentSort = filter;
    }
  }
}
