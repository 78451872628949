// @ts-ignore
import {clearAllBodyScrollLocks, disableBodyScroll} from 'body-scroll-lock';

export class NavigationController {
  private readonly navigationOpenClass = 'body--navOpen';
  private readonly menuSubSelector = '.js-menuSub';
  private readonly menuSubActiveClass = 'nav__listItemSub--active';
  private readonly body = document.body;
  private readonly headerHeight = 15;
  private readonly headerStickyClass = 'body--stickyHeader';
  private firstHamburgerClick = true;
  private firstLoadHappened = false;
  private rafTimer;
  private header;
  private menuButton;
  private overlay;

  constructor() {
    this.header = document.querySelector('.js-header');
    this.menuButton = document.querySelector('.js-hamburger');
    this.overlay = document.querySelector('.js-headerOverlay');
    this.addHamburgerClickWatcher();
    this.addSubmenuClickWatcher();
    this.addScrollListener();
    this.progressScrollEvent();
  }

  /**
   * add click listener for hamburger
   */
  protected addHamburgerClickWatcher(): void {
    if (this.menuButton) {
      const openText = this.menuButton.dataset.opentext || '';
      const closeText = this.menuButton.dataset.closetext || '';

      this.menuButton.addEventListener('click', () => {
        if (this.firstHamburgerClick) {
          this.body.classList.add('body--animationsReady');
          this.firstHamburgerClick = false;
        }
        this.toggleNavigation(openText, closeText);
      });

      this.overlay.addEventListener('click', () => {
        this.toggleNavigation(openText, closeText);
      });
    }
  }

  /**
   * toggle navigation
   */
  protected toggleNavigation(openText: string, closeText: string) {
    if (this.body.classList.contains(this.navigationOpenClass)) {
      this.body.classList.remove(this.navigationOpenClass);
      this.menuButton.setAttribute('title', openText);
      this.menuButton.setAttribute('aria-label', openText);
      clearAllBodyScrollLocks();
      // clearAllBodyScrolllocks seems to kill the scroll Listener for the sticky nav; reenable:
      this.addScrollListener();
      // wait until menu is closed, then close all submenus
      window.setTimeout(() => {
        this.closeAllSubmenus();
        // tslint:disable-next-line:align
      }, 200);
    } else {
      this.body.classList.add(this.navigationOpenClass);
      this.menuButton.setAttribute('title', closeText);
      this.menuButton.setAttribute('aria-label', closeText);
      disableBodyScroll(this.header, {reserveScrollBarGap: true});
    }
  }

  /**
   * add change listener for submenus
   */
  protected addSubmenuClickWatcher(): void {
    const subMenuButtons = document.querySelectorAll(this.menuSubSelector);
    [].forEach.call(subMenuButtons, (subMenuButton) => {
      subMenuButton.addEventListener('click', () => {
        subMenuButton.classList.toggle(this.menuSubActiveClass);
      });
    });
  }

  /**
   * remove active class on all submenu buttons
   */
  protected closeAllSubmenus(): void {
    const subMenuButtons = document.querySelectorAll(this.menuSubSelector + ':not(.nav__listItem--activeInitial)');
    [].forEach.call(subMenuButtons, (subMenuButton) => {
      subMenuButton.classList.remove(this.menuSubActiveClass);
    });
  }

  protected addScrollListener() {
    window.addEventListener('scroll', () => {
      this.progressScrollEvent();
    });
  }

  private progressScrollEvent() {
    cancelAnimationFrame(this.rafTimer);
    this.rafTimer = requestAnimationFrame(() => {
      if (this.firstLoadHappened) {
        this.header.querySelector('.js-logo').classList.add('logo--withTransition');
        let svg = this.header.querySelector('.js-headerTriangleWrapper');
        if (svg.classList !== null && svg.classList !== undefined) {
          svg.classList.add('header__triangleWrapper--withTransition');
        }
      }

      let elementDistanceHtml = window.pageYOffset || document.documentElement.scrollTop || document.body.scrollTop;
      if (elementDistanceHtml > this.headerHeight) {
        this.body.classList.add(this.headerStickyClass);
      } else if (elementDistanceHtml < this.headerHeight) {
        this.body.classList.remove(this.headerStickyClass);
      }

      this.firstLoadHappened = true;
    });
  }
}
