export class FormValidator {
  private forms;
  private submitButtons;
  private loadingAnimations;
  private validationErrorObjects;
  private requiredInputs;

  constructor() {
    if (document.querySelectorAll('.js-form').length) {
      this.initializeForm();
    }
  }

  initializeForm() {
    this.forms = document.getElementsByClassName('js-form');
    this.submitButtons = document.querySelectorAll('button[type=submit]');
    this.loadingAnimations = document.getElementsByClassName('spinner');
    this.validationErrorObjects = document.getElementsByClassName('validationError__message');
    this.requiredInputs = document.querySelectorAll('[required]');
    if (this.submitButtons !== null ) {
      for (let submitButton of this.submitButtons) {
        submitButton.addEventListener('click', (): void => {
          const invalidInputs = submitButton.closest('.js-form').querySelectorAll('input:invalid');
          invalidInputs.forEach((invalidInput): void => {
            invalidInput.parentElement.classList.add('field--error');
            invalidInput.addEventListener('focus', (): void => {
              invalidInput.parentElement.classList.remove('field--error');
            });
          });
        });
        if (this.loadingAnimations !== null) {
          for (let form of this.forms) {
            form.addEventListener('submit', (): void => {
              form.querySelector('.button[type=submit]').classList.add('hide');
              form.querySelector('.spinner').classList.remove('hide');
            });
          }
        }
      }
    }
    if (this.validationErrorObjects.length > 0) {
      for (let validationError of this.validationErrorObjects) {
        let errorInput = document.querySelector('input[id=' + validationError.dataset.source + ']');
        if (errorInput !== null && errorInput.parentElement !== null) {
          errorInput.parentElement.classList.add('field--error');
          errorInput.addEventListener('focus', (): void => {
            if (errorInput !== null && errorInput.parentElement !== null) {
              errorInput.parentElement.classList.remove('field--error');
            }
          });
        }
      }
    }
    for (let requiredInput of this.requiredInputs) {
      requiredInput.previousElementSibling.classList.add('field__label--required');
    }
  }
}
