export class SlotAndOfferNumberController {
  private activeSlotDivs;
  private offerLists;
  private offerCountClass = '.js-offerCount';

  constructor() {
    this.activeSlotDivs = document.querySelectorAll('.activeSlots');
    this.offerLists = document.querySelectorAll('.slot__offers');
    if (this.activeSlotDivs.length) {
      this.initializeSlotNumberController();
    }
    if (this.offerLists.length) {
      this.initializeOfferNumberController();
    }
  }

  initializeSlotNumberController() {
    for (let activeSlotDiv of this.activeSlotDivs) {
      let slots = activeSlotDiv.querySelectorAll('.activeSlots__slot');
      for (let i = 1; i <= slots.length; i++) {
        let slotNumber = document.createElement('div');
        slotNumber.classList.add('slot__number');
        slotNumber.innerHTML = i + '';
        slots[i - 1].insertBefore(slotNumber, slots[i - 1].firstChild);
      }
    }
  }

  initializeOfferNumberController() {
    for (let offerList of this.offerLists) {
      let offersCount = offerList.querySelectorAll('.slot__offer').length;
      if (offerList.previousElementSibling.querySelector(this.offerCountClass)) {
        offerList.previousElementSibling.querySelector(this.offerCountClass).innerHTML = offersCount;
      }
      if (offersCount >= 1) {
        if (offerList.closest('.inquiry__detail') !== null) {
          offerList.closest('.inquiry__detail').querySelector('.js-carpentryList__quicklink').classList.add('hide');
        }
        let offers = offerList.querySelectorAll('.slot__offer');
        for (let i = 1; i <= offers.length; i++) {
          offers[i - 1].querySelector('.js-offerNumber').innerHTML = i + '';
        }
      }

      if (offersCount > 0) {
        if (offerList.closest('.carpentryList__inquiry').querySelector('.js-inquiry__status-2') !== null &&
            offerList.closest('.carpentryList__inquiry').querySelector('.js-inquiry__status-3') !== null) {
          offerList.closest('.carpentryList__inquiry').querySelector('.js-inquiry__status-2').classList.add('hide');
          offerList.closest('.carpentryList__inquiry').querySelector('.js-inquiry__status-3').classList.remove('hide');
        }
      }
    }
  }
}
