export class LoginStateController {

  constructor() {
    const authenticationContainers = document.querySelectorAll('.js-login-request');
    if (authenticationContainers.length > 0) {
      [].forEach.call(authenticationContainers, (authenticationContainer) => {
        this.getLoginState(authenticationContainer);
      });
    }
  }

  private async getLoginState(authenticationContainer) {
    const data = await this.fetchLoginState(authenticationContainer.dataset.url);
    authenticationContainer.insertAdjacentHTML('beforeend', data);
  }

  private async fetchLoginState(url) {
    const response = await fetch(url);
    return await response.text();
  }
}
