import {FocusedImage} from 'image-focus';

export function initFocusPoint(): void {
  // @todo: focus images after images are loaded!
  focusImages();
}

function focusImages(): void {
  const focusPointImages = document.querySelectorAll('.js-focuspoint') as NodeListOf<HTMLImageElement>;

  Array.prototype.forEach.call(focusPointImages, (image: HTMLImageElement) => {
    new FocusedImage(image, {
      updateOnWindowResize: true,
    });
  });
}
