export class OfferSumController {
  private readonly forms;

  constructor() {
    this.forms = document.querySelectorAll('.js-offer-form');
    if (this.forms.length) {
      this.initializeOfferSumController();
    }
  }
  initializeOfferSumController() {
    for (let form of this.forms) {
      let offerSumElement = form.querySelector('.js-offerSum');
      let sumInputs = form.querySelectorAll('.js-sum-input');
      for (let input of sumInputs) {
        input.addEventListener('keyup', () => {
          let sum = 0;
          for (let inputInnerLoop of sumInputs) {
            if (!isNaN(parseFloat(inputInnerLoop.value))) {
              sum += parseFloat(inputInnerLoop.value);
            }
          }
          offerSumElement.innerHTML = sum.toLocaleString(undefined, {maximumFractionDigits: 2}) + ' €';
        });
        input.addEventListener('keypress', (event) => {
          if (event.key === '.') {
            event.preventDefault();
          } else {
            let currentInputValue = input.value;
            let newInputValue = currentInputValue + event.key;
            if (!/^[0-9]+.?([0-9]{1,2})?$/.test(newInputValue)) {
              event.preventDefault();
            }
          }
        });
      }
    }
  }
}
